import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { motion, useAnimation } from 'framer-motion'

import Layout from '../layouts'
import SEO from '../components/Seo'
import Container from '../components/Container'
import LogoImage from '../images/logo.svg'

const Logo = styled(motion.img)`
  width: 300px;
  margin-top: 40px;
  margin-left: -25px;
  margin-bottom: 0;
  vertical-align: middle;

  @media screen and (min-width: 719px) {
    width: 400px;
    margin-top: 30px;
    margin-left: -36px;
  }
`

const Columns = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 719px) {
    flex-direction: row;    
    justify-content: space-between;
    margin-bottom: 4rem;
  }
`

const ContentContainer = styled.div`
  order: 2;
  margin-top: -4rem;

  @media screen and (min-width: 719px) {
    order: 1;
    margin-top: 0;
  }
`

const MainContent = styled(motion.div)`
  margin-top: 1rem;

  @media screen and (min-width: 719px) {
    margin-top: 6rem;
  }
`

const ImageContainer = styled(motion.div)`
  order: 1;
  align-self: flex-end;

  @media screen and (min-width: 719px) {
    order: 2;
    align-self: flex-start;
  }
`

const ImageMotion = styled(motion.div)`
  transform-origin: 50% 50%;
`

const Image = styled(Img)`
  border-radius: 50%;
  cursor: pointer;
  transform: scale(0.7);

  @media screen and (min-width: 719px) {
    max-width: 100%;
    transform: scale(1);
  }
`

const ContactText = styled.p`
  @media screen and (min-width: 719px) {
    margin: 3.5rem 0 0;
  }
`

const ContactButton = styled(motion.a)`
  display: inline-block;
  border: 0;
  border-radius: 4px;
  background: #ffbc2d;
  color: white;
  padding: 0.05rem 0.5rem;
  cursor: pointer;
  outline: none;

  &:hover {
    text-decoration: none;
  }
`

const IndexPage = ({ data }) => {
  const { node: frontPageNode } = data.frontPage.edges[0];
  const imageUrl = frontPageNode.frontmatter?.main_image?.childImageSharp.fixed;
  const imageControls = useAnimation();

  const buttonMotion = {
    rest: {
      scale: 1,
      boxShadow: '0px 1px 1px rgba(0,0,0,0)',
      backgroundColor: '#ffbc2d'
    },
    hover: {
      backgroundColor: 'transparent',
      color: '#ffbc2d',
      transition: {
        duration: 0,
        ease: 'easeInOut'
      }
    }
  }

  const triggerImageAnimation = () => {
    imageControls.start({
      rotate: [0, 180, 0],
      x: [0, 250, 0],
      y: [0, 115, 0],
      transition: {
        duration: 2.5
      }
    })
  }

  return (
    <Layout>
      <SEO title="Sami Koodaa Oy" />
      <Container>
        <Columns>
          <ContentContainer>
            <Logo
              initial={{
                y: -500,
                opacity: 0
              }}
              animate={{
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.5
                }
              }}
              src={LogoImage} alt="Sami Koodaa"
            />

            <MainContent
              initial={{
                x: -1000,
                opacity: 0
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  duration: 0.5
                }
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: frontPageNode.html }} />
              {/* <ContactText>Voit ottaa minuun yhteyttä <ContactButton
                initial='rest'
                whileHover='hover'
                animate='rest'
                variants={buttonMotion}
                href='mailto:sami@samikoodaa.fi'
              >sähköpostilla</ContactButton> tai <ContactButton 
                href="https://www.linkedin.com/in/sami-juvonen/"
                initial='rest'
                whileHover='hover'
                animate='rest'
                variants={buttonMotion}
              >LinkedInissä</ContactButton>.</ContactText> */}
            </MainContent>
          </ContentContainer>

          {imageUrl && (
            <ImageContainer
              initial={{
                opacity: 0,
                x: 500
              }}
              animate={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 0.5
                }
              }}
            >
              <ImageMotion
                initial={{
                  y: 0
                }}
                animate={imageControls}
                onClick={() => triggerImageAnimation()}
              >
                <Image 
                  fixed={imageUrl}
                />
              </ImageMotion>
            </ImageContainer>
          )}
        </Columns>
      </Container>
      
      {/*<Projects />*/}
    </Layout>
  )
}

export const query = graphql`
  query FrontPageQuery {
    frontPage: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "front_page" }}}
      limit: 1
    ) {
      edges {
        node {
          frontmatter{
            title
            description
            main_image {
              childImageSharp {
                fixed(width: 200, height: 200, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          html
        }
      }
    }
    countdownTimerImage: allFile(filter: {name: {eq: "countdown_timer"}}) {
      edges {
        node {
          childImageSharp {
            fixed(width: 300, height: 280) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

export default IndexPage
